<template>
    <div class="section-news-detail" id="detail_news">
        <div class="container">
            <h2 class="section-news-detail__title"><img :src="webp(require(`../../../public/img/news/news-${$i18n.locale}.png`))" alt="News"></h2>
            <div class="section-news-detail__warning">
                <Breadcrumb :post="post"/>
                <div class="section-news-detail__content">
                    <div class="date_news_detail">{{ post.date | formatDate(dateFormat, locale) }}</div>
                    <h1 class="title_news_detail" v-html="post.title?post.title.rendered:''"></h1>
                    <div class="content_news_detail" v-html="post.content?post.content.rendered:''"></div>
                    
                    <div class="btn-black"><a href="/news/"><img :src="webp(require(`../../../public/img/icon/icon-arrow-half.png`))" alt="">back</a></div>
                </div>

                <div class="block_share">
                    <a class="item" :href="`https://www.facebook.com/sharer/sharer.php?u=`+encodeURIComponent(current_url)" title="Share by Facebook" target="_blank"><img src="../../../public/img/news/icon/facebook.png" alt=""></a>
                </div>
            </div><!--/ .section-news-detail__content -->
        </div><!--/ container -->
    </div>
</template>

<script>
    import Breadcrumb from '../news/Breadcrumb'

    export default {
        name: "Detail",
        components: {
            Breadcrumb
        },
        props: {
            post: Object,
            locale: String
        },
        data() {
            return {
                current_url: window.location.href,
            }
        },
        computed: {
            dateFormat() {
                return this.locale === 'vi' ? 'dddd, ngày DD, MMMM, YYYY' : 'dddd, MMMM Do, YYYY'
            }
        },
        metaInfo () {
            return {
                title: this.post.yoast_meta ? this.post.yoast_meta.yoast_wpseo_title : '',
                meta: [
                    { name: 'description', content: this.post.yoast_meta ? this.post.yoast_meta.yoast_wpseo_metadesc : '', vmid: 'description' },
                    { property: 'og:title', content: this.post.yoast_meta ? this.post.yoast_meta.yoast_wpseo_title : '', vmid: 'og:title' },
                    { property: 'og:description', content: this.post.yoast_meta ? this.post.yoast_meta.yoast_wpseo_metadesc : '', vmid: 'og:description' },
                    { property: 'og:image', content: this.post.better_featured_image ? this.post.better_featured_image.source_url : '', vmid: 'og:image' },
                    { property: 'og:url', content: window.location.href, vmid: 'og:url' },
                    { property: 'og:type', content: 'article', vmid: 'og:type' }
                ]
            }
        },
    }
</script>

<style lang="scss">
    @import '../../assets/scss/mixin/_mixin';

    $sm: 768;
    $md: 992;
    $lg: 1200;

    .section-news-detail {
        @include media(set-range, $lg - 1, $sm) {
            padding-top: 5rem;
        }
        @include media(set-min-w, $lg) {
            padding-top: 9.7rem;
        }

        &__title {
            position: relative;
            display: none;
            padding-bottom: .7rem;
            padding-left: .8rem;
            @include media(set-min-w, $sm) {
                display: block;
                margin-bottom: 4.6rem;
            }
            @include media(set-min-w, $lg) {
                margin-bottom: 7.6rem;
            }

            &::after {
                content: '';
                display: none;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1.5rem;
                background-color: #ffaf04;
                border-radius: 5rem;
                z-index: -1;
                @include media(set-min-w, $sm) {
                    display: block;
                }
            }
        }

        &__warning {
            max-width: 87rem;
            padding: 2.5rem 1rem 1rem;
            margin: 0 auto;
            @include media(set-min-w, $sm) {
                padding-top: 0;
            }
            @include media(set-min-w, $md) {
                padding-bottom: 3rem;
            }

            .block_share {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding-top: 1.5rem;
                padding-bottom: 1.5rem;
                text-align: right;

                .item {
                    margin-left: 1rem;
                }

                img {
                    height: 2.4rem;
                    filter: invert(.7);
                }
            }
        }

        &__content {
            position: relative;
            padding-top: 2.5rem;
            padding-bottom: 1rem;
            @include media(set-min-w, $md) {
                padding-top: 0;
            }

            .btn-black {
                position: absolute;
                top: 0;
                right: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 3.8rem;
                height: 3.8rem;
                background-color: #efefef;
                line-height: 1.2rem;
                color: #000;
                font-size: 1.2rem;
                text-align: center;
                @include media(set-min-w, $md) {
                    display: none;
                }

                a {
                    display: block;
                    color: inherit;
                }

                img {
                    max-width: 2.5rem;
                    transform: rotateY(-180deg);
                }
            }
        }

        .content_news_detail {
            line-height: 2.3rem;
            color: #272626;
            font-size: 1.4rem;
            @include media(set-min-w, $sm) {
                line-height: 3.1rem;
                font-size: 1.6rem;
            }

            p {
                margin-bottom: 1.5rem;
            }
        }

        .date_news_detail {
            padding-right: 4rem;
            margin-bottom: 1.5rem;
            line-height: 2.6rem;
            color: #000;
            font-size: 1.5rem;
            &::first-letter {
                text-transform: uppercase;
            }
            @include media(set-min-w, $md) {
                padding-right: 0;
            }
        }

        .title_news_detail {
            margin-bottom: 1.5rem;
            line-height: 3.2rem;
            color: #292929;
            font-size: 2.4rem;
            font-weight: 900;

            @include media(set-min-w, $sm) {
                line-height: 4rem;
                font-size: 3.2rem;
            }
        }

        .list_news_detail {
            padding-right: 1rem;
            padding-left: 1rem;
            color: #272626;

            li {
                position: relative;
                padding-left: 1.7rem;
                margin-bottom: 1.5rem;

                &:before {
                    content: '';
                    position: absolute;
                    top: .8rem;
                    left: 0;
                    width: .7rem;
                    height: .7rem;
                    background-color: #ffaf04;
                }
            }
        }

        /* Typography -------------------------------- */
        .content_news_detail {
            color: #191e23;
            font-size: 18px;
            @include media(set-min-w, $sm) {
                font-size: 21px;
            }

            > :first-child {
                margin-top: 0
            }

            > :last-child {
                margin-bottom: 0
            }

            a {
                color: #cd2653;
                text-decoration: underline;

                &:focus,
                &:hover {
                    text-decoration: none;
                }
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                font-feature-settings: "lnum";
                font-variant-numeric: lining-nums;
                font-weight: 700;
                letter-spacing: -0.0415625em;
                line-height: 1.25;
                margin: 40px 0 25px;
            }

            h1 {
                font-size: 36px;
                font-weight: 800;
                line-height: 1.138888889;
                @include media(set-min-w, $sm) {
                    font-size: 64px;
                }
                @include media(set-min-w, $lg) {
                    font-size: 84px;
                }
            }

            h2 {
                font-size: 32px;
                @include media(set-min-w, $sm) {
                    font-size: 48px;
                }
            }

            h3 {
                font-size: 28px;
                @include media(set-min-w, $sm) {
                    font-size: 40px;
                }
            }

            h4 {
                font-size: 24px;
                @include media(set-min-w, $sm) {
                    font-size: 32px;
                }
            }

            h5 {
                font-size: 21px;
                @include media(set-min-w, $sm) {
                    font-size: 24px;
                }
            }

            h6 {
                font-size: 16px;
                letter-spacing: .5px;
                text-transform: uppercase;
                @include media(set-min-w, $sm) {
                    font-size: 18px;
                }
            }

            li,
            p {
                line-height: 1.8;
            }

            p,
            ol,
            ul,
            dl,
            dt {
                font-family: NonBreakingSpaceOverride, "Hoefler Text", Garamond, "Times New Roman", serif;
                letter-spacing: normal;
            }

            p {
                margin-top: 28px;
                margin-bottom: 28px;
            }

            ol,
            ul {
                padding-left: 2.5em;
                margin-top: 21px;
                margin-bottom: 28px;
                margin-left: 0;

                ul {
                    list-style-type: circle;
                }

                ul,
                ol {
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }

            ul {
                list-style-type: disc;
            }

            li {
                list-style-type: inherit;
            }

            dl {
                line-height: 1.3;
            }

            dd {
                margin-bottom: 6px;
                margin-left: 40px;
            }

            blockquote {
                padding-left: 1.8rem;
                margin: 0;
                box-shadow: inset 0 0 0 0 #e2e4e7;
                border-left: 4px solid #000;
            }

            pre {
                padding: 14px;
                margin: 14px 0;
                background: transparent;
                border-radius: 0;
                border-color: #dcd7ca;
                color: inherit;
                font-family: monospace;
                font-size: 14px;
                white-space: pre-wrap;
                line-height: 1.5;
                letter-spacing: -.24px;
            }

            cite {
                color: #6d6d6d;
                font-size: 14px;
                font-style: italic;
                font-weight: 600;
                line-height: 1.25;
                font-family: "Inter var", -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif;
            }

            address,
            em {
                font-style: italic;
            }

            kbd,
            samp {
                font-family: monospace;
            }

            code {
                padding: 2px;
                margin: 0 1px;
                border-radius: 2px;
                background: #f3f4f5;
                direction: ltr;
                unicode-bidi: embed;
                color: #23282d;
                font-size: 14px;
                font-family: Menlo,Consolas,monaco,monospace;
            }

            kbd {
                padding: 4px 6px;
                margin: 0 1px;
                background: #eaeaea;
                background: rgba(0,0,0,.07);
                border-radius: 0;
                font-size: 15.75px;
            }

            blockquote,
            q {
                quotes: none
            }

            table {
                border-collapse: separate;
                border-spacing: 2px;

                &,
                td,
                th,
                caption {
                    border: 1px dashed #BBB
                }
            }

            hr {
                border: 0;
                border-top: 1px solid #ddd;
                border-bottom: 1px solid #fafafa
            }
        }
    }
</style>
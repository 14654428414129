<template>
    <div class="breadcrumb">
        <ul class="breadcrumb-list">
            <li class="breadcrumb-item">
                <a href="/" class="breadcrumb-item__link home"></a>
            </li>
            <li class="breadcrumb-item">
                <a href="/news" class="breadcrumb-item__link" v-html="$t(`news`)"></a>
            </li>
            <li class="breadcrumb-item" v-if="post">
                <span class="breadcrumb-item__link" v-html="post.title?post.title.rendered:''"></span>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: 'Breadcrumb',
        props: {
            post: Object
        },
    }
</script>

<style lang="scss" scoped>
    @import '../../assets/scss/mixin/_mixin';

    .breadcrumb {
        margin-top: 1rem;
        margin-bottom: .5rem;

        &-list {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }

        &-item {
            margin-bottom: .5rem;

            a::after {
              content: '';
              position: absolute;
              bottom: 2px;
              left: 3rem;
              right: 0;
              height: 1px;
              background-color: #000;
            }

            & + & &__link {
                padding-left: 3rem;
            }

            &__link {
                position: relative;
                display: block;
                line-height: normal;
                color: #000;
                font-size: 2rem;

                @include media(tablet) {
                    font-size: 1.8rem;
                }
                @include media(phone) {
                    font-size: 1.6rem;
                }

                &::before {
                    content: '/';
                    position: absolute;
                    top: 50%;
                    left: 1.2rem;
                    transform: translateY(-50%);
                    font-size: 2rem;
                }

                &.home {
                    width: 2.5rem;
                    height: 2.5rem;
                    background: url('../../../public/img/icon/home-icon.png') no-repeat left center;

                    &::before,
                    &::after {
                        display: none
                    }
                }
            }

            &:last-child &__link {
                text-decoration: none;
                pointer-events: none;

                &::after {
                    display: none;
                }
            }
        }
    }
    .webp {
        .breadcrumb {
            &-item {
                &__link {
                    &.home {
                        background: url('../../../public/img/icon/home-icon.webp') no-repeat left center;
                    }
                }
            }
        }
    }
</style>
